export const cities = [
  "Select your Location",
  "Adampan",
  "Addalaichenai",
  "Adippala",
  "Agalawatta",
  "Agaliya",
  "Agarapathana",
  "Ahangama",
  "Ahungalla",
  "Akaragama",
  "Akarawita",
  "Akarella",
  "Akkaraipattu",
  "Akkarasiyaya",
  "Akkarayankulam",
  "Akmeemana",
  "Akuramboda",
  "Akurana",
  "Akuressa",
  "Alahengama",
  "Alahitiyawa",
  "Alampil",
  "Alapaladeniya",
  "Alawala",
  "Alawatugoda",
  "Alawatura",
  "Alawatuwala",
  "Alawwa",
  "Algama",
  "Allaipiddi",
  "Allaveddi",
  "Alubomulla",
  "Aludeniya",
  "Aluketiyawa",
  "Alutgama",
  "Aluthwala",
  "Alutnuwara",
  "Aluttaramma",
  "Alutwewa",
  "Alvai",
  "Alwatta",
  "Ampara",
  "Ambadandegama",
  "Ambagahapelessa",
  "Ambagahawatta",
  "Ambagamuwa Udabulathgama",
  "Ambagasdowa",
  "Ambagaspitiya",
  "Ambakandawila",
  "Ambakote",
  "Ambalakanda",
  "Ambalangoda",
  "Ambalantota",
  "Ambana",
  "Ambanpola",
  "Ambatalawa",
  "Ambatenna",
  "Ambepussa",
  "Ambewela",
  "Ambulugala",
  "Amitirigala",
  "Ampagala",
  "Ampara",
  "Ampegama",
  "Ampilanthurai",
  "Ampitiya",
  "Amugoda",
  "Amunumulla",
  "Anaicoddai",
  "Analaitivu",
  "Anamaduwa",
  "Anangoda",
  "Andiambalama",
  "Andigama",
  "Andiyagala",
  "Angamuwa",
  "Angoda",
  "Angulugaha",
  "Angunakolapelessa",
  "Angunakolawewa",
  "Angunawila",
  "Anguruwatota",
  "Anhandiya",
  "Anhettigama",
  "Ankokkawala",
  "Ankumbura",
  "Anukkane",
  "Anuradhapur",
  "Anuradhapura",
  "Aparekka",
  "Aragoda",
  "Araipattai",
  "Aralaganwila",
  "Aranayaka",
  "Arangala",
  "Arawa",
  "Arawakumbura",
  "Arawatta",
  "Arippu",
  "Aruggammana",
  "Aselapura",
  "Atabage",
  "Atakalanpanna",
  "Atakiriya",
  "Atale",
  "Ataragalla",
  "Ataragallewa",
  "Atchuveli",
  "Athimottai",
  "Athuraliya",
  "Athurugiriya",
  "Attanagalla",
  "Attanakadawala",
  "Attawilluwa",
  "Avissawella",
  "Awukana",
  "Awulegama",
  "Ayagama",
  "Ayithiyamalai",
  "Ayiwela",
  "Badalgama",
  "Badalkumbura",
  "Baddegama",
  "Badulla",
  "Badulla",
  "Baduluoya",
  "Baduluwela",
  "Baduraliya",
  "Bakamuna",
  "Bakiella",
  "Bakinigahawela",
  "Bakmitiyawa",
  "Balaharuwa",
  "Balalla",
  "Balana",
  "Balangoda",
  "Balapitiya",
  "Ballaketuwa",
  "Bambalapitiya",
  "Bambaragahaela",
  "Bambaragaswewa",
  "Bambarapana",
  "Bamunukotuwa",
  "Banagala",
  "Bandagiriya Colony",
  "Bandara Koswatta",
  "Bandaragama",
  "Bandarawela",
  "Banduragoda",
  "Bangadeniya",
  "Baranankattuwa",
  "Barawakumbuka",
  "Barawardhana Oya",
  "Batagolladeniya",
  "Batapola",
  "Batatota",
  "Batawala",
  "Battaramulla",
  "Batticaloa",
  "Batticaloa",
  "Battuluoya",
  "Batugampola",
  "Batugoda",
  "Batumulla",
  "Batuwatta",
  "Batuwita",
  "Bawlana",
  "Beliatta",
  "Beligamuwa",
  "Belihuloya",
  "Bellana",
  "Bemmulla",
  "Bengamuwa",
  "Bentota",
  "Beragama",
  "Beralapanathara",
  "Beralihela",
  "Beramada",
  "Berannawa",
  "Beruwala",
  "Bibile",
  "Bibilegama",
  "Bingiriya",
  "Biyagama IPZ",
  "Biyagama",
  "Bogahakumbura",
  "Bogahawewa",
  "Bogamulla",
  "Bogawantalawa",
  "Bokalagama",
  "Bolossagama",
  "Bolthumbe",
  "Bombuwala",
  "Bomluwageaina",
  "Boossa",
  "Bopagama",
  "Bopagoda",
  "Bopana",
  "Bopattalawa",
  "Bope",
  "Bopitiya",
  "Boragas",
  "Boralanda",
  "Boralankada",
  "Boralesgamuwa",
  "Boralugoda",
  "Boraluwewa",
  "Borella",
  "Bossella",
  "Bowalagama",
  "Bowela",
  "Boyagane",
  "Buddama",
  "Bujjampola",
  "Bujjomuwa",
  "Bulathkohupitiya",
  "Bulathsinhala",
  "Bulutota",
  "Buluwala",
  "Bundala",
  "Buthpitiya",
  "Buttala",
  "Central Camp",
  "Chankanai",
  "Chavakachcheri",
  "Cheddikulam",
  "Cheddipalayam",
  "Chemamadukkulam",
  "Chenkaladi",
  "Chilavathurai",
  "Chilaw",
  "Chullipuram",
  "Chundikuli",
  "Chunnakam",
  "Colombo 01",
  "Colombo 02",
  "Colombo 03",
  "Colombo 04",
  "Colombo 05",
  "Colombo 06",
  "Colombo 07",
  "Colombo 08",
  "Colombo 09",
  "Colombo 10",
  "Colombo 11",
  "Colombo 12",
  "Colombo 13",
  "Colombo 14",
  "Colombo 15",
  "Colombo",
  "Dadayamtalawa",
  "Dagampitiya",
  "Dagonna",
  "Dalukana",
  "Damana",
  "Damanewela",
  "Dambadeniya",
  "Dambagalla",
  "Dambana",
  "Dambulla",
  "Dambuluwana",
  "Damminna",
  "Dampahala",
  "Damunupola",
  "Danawala Thiniyawala",
  "Dankanda",
  "Dankotuwa",
  "Danowita",
  "Danture",
  "Daraluwa",
  "Daugala",
  "Dayagama Bazaar",
  "Debahera",
  "Debathgama",
  "Dedigamuwa",
  "Dedugala",
  "Dedunupitiya",
  "Deegala Lenama",
  "Deegalla",
  "Deegawapiya",
  "Deewala Pallegama",
  "Dehiattakandiya",
  "Dehiowita",
  "Dehiwala",
  "Deiyandara",
  "Dekatana",
  "Dekinda",
  "Dela",
  "Deldeniya",
  "Delft",
  "DelftWest",
  "Delgoda",
  "Dellawa",
  "Delmella",
  "Deloluwa",
  "Deltara",
  "Deltota",
  "Delwagura",
  "Delwala",
  "Delwite",
  "Demalagama",
  "Demanhandiya",
  "Demataluwa",
  "Dematawewa",
  "Demodara",
  "Demuwatha",
  "Denagama",
  "Denipitiya",
  "Deniyaya",
  "Derangala",
  "Deraniyagala",
  "Devagiriya",
  "Devalahinda",
  "Dewagala",
  "Dewahuwa",
  "Dewalapola",
  "Dewalegama",
  "Dewanagala",
  "Dharga Town",
  "Diddeniya",
  "Digamadulla Weeragoda",
  "Diganatenna",
  "Digannewa",
  "Dikkapitiya",
  "Dikkumbura",
  "Dikoya",
  "Dikwella",
  "Dimbulagala",
  "Dimbulana",
  "Divulankadawala",
  "Divulapelessa",
  "Divulapitiya",
  "Divuldamana",
  "Divuldeniya",
  "Divullegoda",
  "Diwalakada",
  "Diyabeduma",
  "Diyagaha",
  "Diyakobala",
  "Diyalape",
  "Diyasenpura",
  "Diyatalawa",
  "Dodampe",
  "Dodanduwa",
  "Dodangaslanda",
  "Dodangoda",
  "Dolapihilla",
  "Dolosbage",
  "Doloswalakanda",
  "Doluwa",
  "Dombagahawela",
  "Dombagoda",
  "Dombemada",
  "Dompe",
  "Doragala",
  "Doragamuwa",
  "Dorakumbura",
  "Doratiyawa",
  "Dorawaka",
  "Dulgolla",
  "Dullewa",
  "Dumbara Manana",
  "Dummalasuriya",
  "Dunagaha",
  "Dunkannawa",
  "Dunkolawatta",
  "Dunukedeniya",
  "Dunumadalawa",
  "Dunumala",
  "Dunuwila",
  "Dunuwilapitiya",
  "Dutuwewa",
  "Egodawela",
  "Eheliyagoda",
  "Ehetuwewa",
  "Ekala",
  "Ekamutugama",
  "Ekiriya",
  "Ekiriyankumbura",
  "Elahera",
  "Elamulla",
  "Elapatha",
  "Elayapattuwa",
  "Elephant Pass",
  "Elibichchiya",
  "Elkaduwa",
  "Ella",
  "Ella",
  "Ellagala",
  "Ellagawa",
  "Ellakkala",
  "Ellaulla",
  "Ellawala",
  "Ellewewa",
  "Elpitiya",
  "Eluthumadduval",
  "Eluvaitivu",
  "Eluwankulama",
  "Embilipitiya",
  "Embogama",
  "Embuldeniya",
  "Eppawala",
  "Eratna",
  "Eravur",
  "Erawula Junction",
  "Erepola",
  "Erlalai",
  "Erukkalampiddy",
  "Essella",
  "Etanawala",
  "Etawatunuwewa",
  "Etaweeragollewa",
  "Ethimalewewa",
  "Ethkandura",
  "Ettale",
  "Ettampitiya",
  "Ettiliwewa",
  "Etulgama",
  "Etungahakotuwa",
  "Gabbela",
  "Galabedda",
  "Galaboda",
  "Galadivulwewa",
  "Galagedara",
  "Galaha",
  "Galamuna",
  "Galapitagala",
  "Galapitamada",
  "Galatara",
  "Galauda",
  "Galedanda",
  "Galenbindunuwewa",
  "Galewela",
  "Galgamuwa",
  "Galhinna",
  "Galigamuwa Town",
  "Galkadawala",
  "Galkiriyagama",
  "Galkulama",
  "Galle",
  "Galle",
  "Gallella",
  "Gallellagama",
  "Gallewa",
  "Galmuruwa",
  "Galnewa",
  "Galoya Junction",
  "Galpatha",
  "Galporuyaya",
  "Gamagoda",
  "Gambirigaswewa",
  "Gamewela",
  "Gammaduwa",
  "Gampaha",
  "Gampaha",
  "Gampola",
  "Gandara",
  "Ganegoda",
  "Ganemulla",
  "Ganewalpola",
  "Gangala Puwakpitiya",
  "Gangeyaya",
  "Gangulandeniya",
  "Gantuna",
  "Gawaragiriya",
  "Gawarawela",
  "Gelioya",
  "Gemunupura",
  "Getahetta",
  "Getalawa",
  "Getamanna",
  "Gillimale",
  "Ginigathena",
  "Ginimellagaha",
  "Gintota",
  "Girandurukotte",
  "Girathalana",
  "Giritale",
  "Giriulla",
  "Gnanikulama",
  "Goda Koggalla",
  "Godagampola",
  "Godahena",
  "Godakawela",
  "Godamunna",
  "Godapitiya",
  "Godunna",
  "Gokaralla",
  "Gomagoda",
  "Gomilamawarala",
  "Gonagala",
  "Gonagalpura",
  "Gonagamuwa Uduwila",
  "Gonagantenna",
  "Gonagolla",
  "Gonahaddenawa",
  "Gonakele",
  "Gonamulla Junction",
  "Gonapinuwala",
  "Gonapola Junction",
  "GonawalaWP",
  "Gonawalapatana",
  "Gonawila",
  "Gongodawila",
  "Gonnoruwa",
  "Govinna",
  "Gunnepana",
  "Gurubewilagama",
  "Gurudeniya",
  "Gurulubadda",
  "Gurutalawa",
  "Habaraduwa",
  "Habarakada",
  "Habarana",
  "Haburugala",
  "Haggala",
  "Hakahinna",
  "Hakbellawaka",
  "Hakmana",
  "Hakuruwela",
  "Haldummulla",
  "Halgranoya",
  "Hali Ela",
  "Halkandawila",
  "Halloluwa",
  "Halmillawa Dambulla",
  "Halmillawetiya",
  "Halmillawewa",
  "Halpe",
  "Haltota",
  "Halvitigala Colony",
  "Halwala",
  "Halwatura",
  "Halwinna",
  "Hambantota",
  "Hambantota",
  "Hambegamuwa",
  "Handaganawa",
  "Handagiriya",
  "Handapangoda",
  "Handawalapitiya",
  "Handessa",
  "Handugala",
  "Handungamuwa",
  "Hangarapitiya",
  "Hangunnawa",
  "Hanguranketha",
  "Hansayapalama",
  "Hanwella",
  "Hapugastalawa",
  "Hapugastenna",
  "Haputale",
  "Harangalagama",
  "Harankahawa",
  "Harasbedda",
  "Hasalaka",
  "Hatangala",
  "Hatarabage",
  "Hataraliyadda",
  "Hatton",
  "Hattota Amuna",
  "Hawpe",
  "Hebarawa",
  "Hedigalla Colony",
  "Hedunuwewa",
  "Heeloya",
  "Heiyanthuduwa",
  "Helahalpe",
  "Helamada",
  "Helapupula",
  "Hemmatagama",
  "Hendala",
  "Henegama",
  "Hengamuwa",
  "Hettimulla",
  "Hettipola",
  "Hewadiwela",
  "Hewaheta",
  "Hewainna",
  "Hewanakumbura",
  "Hidellana",
  "Hidogama",
  "Hikkaduwa",
  "Hilogama",
  "Hinatiyana Madawala",
  "Hindagala",
  "Hindagolla",
  "Hingula",
  "Hingurakdamana",
  "Hingurakgoda",
  "Hinguralakanda",
  "Hingurana",
  "Hingurukaduwa",
  "Hiniduma",
  "Hiramadagama",
  "Hiripitya",
  "Hiriwadunna",
  "Hiriyala Lenawa",
  "Hiruwalpola",
  "Hiswella",
  "Hitigegama",
  "Hiyare",
  "Hokandara",
  "Homagama",
  "Hondiyadeniya",
  "Hopton",
  "Horagala",
  "Horambawa",
  "Horampella",
  "Horana",
  "Horapawita",
  "Horawpatana",
  "Horewelagoda",
  "Horiwila",
  "Hulandawa",
  "Hulannuge",
  "Hulogedara",
  "Hulugalla",
  "Hungama",
  "Hunnasgiriya",
  "Hunumulla",
  "Hunupola",
  "Hurigaswewa",
  "Hurulunikawewa",
  "Idalgashinna",
  "Ihala Beligalla",
  "Ihala Gomugomuwa",
  "Ihala Katugampala",
  "Ihala Kottaramulla",
  "Ihala Madampella",
  "Ihala Puliyankulama",
  "Ihala",
  "Ihalagama",
  "Ilippadeniya",
  "Imbulana",
  "Imbulgasdeniya",
  "Imbulgoda",
  "Imbulgolla",
  "Inamaluwa",
  "Indulgodakanda",
  "Inginimitiya",
  "Inginiyagala",
  "Inguruwatta",
  "Iranai lluppaikulam",
  "Iranaitiv",
  "Iriyagolla",
  "Ismailpuram",
  "Ithanawatta",
  "Ittademaliya",
  "Ittakanda",
  "Ittapana",
  "Ja-Ela",
  "Jaffna",
  "Jaffna",
  "Jambugahapitiya",
  "Jangulla",
  "Jayanthipura",
  "Jayasiripura",
  "Julampitiya",
  "Kabagamuwa",
  "Kadawatha",
  "Kadigawa",
  "Kadugannawa",
  "Kaduwela",
  "Kagama",
  "Kahaduwa",
  "Kahandamodara",
  "Kahangama",
  "Kahapathwala",
  "Kahatagasdigiliya",
  "Kahatagollewa",
  "Kahataliyadda",
  "Kahataruppa",
  "Kahatowita",
  "Kahawa",
  "Kahawala",
  "Kahawatta",
  "Kaikawala",
  "Kaitadi",
  "Kakkapalliya",
  "Kalaganwatta",
  "Kalagedihena",
  "Kalakarambewa",
  "Kalankuttiya",
  "Kalaoya",
  "Kalatuwawa",
  "Kalawana",
  "Kalawedi Ulpotha",
  "Kalawila Kiranthidiya",
  "Kaleliya",
  "Kalingaela",
  "Kalkudah",
  "Kalladiya",
  "Kallanchiya",
  "Kallar",
  "Kalmunai",
  "Kalpitiya",
  "Kaltota",
  "Kaluaggala",
  "Kalubowila",
  "Kalubowitiyana",
  "Kalubululanda",
  "Kalugahakandura",
  "Kalugala",
  "Kalugamuwa",
  "Kalukele Badanagala",
  "Kalundawa",
  "Kalupahana",
  "Kalutara",
  "Kalutara",
  "Kaluwanchikudi",
  "Kaluwankemy",
  "Kamburugamuwa",
  "Kamburupitiya",
  "Kanadeniyawala",
  "Kanagarayankulam",
  "Kananke Bazaar",
  "Kananwila",
  "Kanattewewa",
  "Kanavil",
  "Kandaketya",
  "Kandakuliya",
  "Kandalama",
  "Kandana",
  "Kandanagama",
  "Kandapola",
  "Kandaudapanguwa",
  "Kandavalai",
  "Kandawinna",
  "Kandegedara",
  "Kandepuhulpola",
  "Kandy",
  "Kandy",
  "Kankesanthurai",
  "Kannakipuram",
  "Kannankudah",
  "Kannattota",
  "Kapugallawa",
  "Kapugoda",
  "Kapuliyadde",
  "Kapuwatta",
  "Karadiyanaru",
  "Karagahagedara",
  "Karagahawewa",
  "Karagahinna",
  "Karagoda Uyangoda",
  "Karagoda",
  "Karainagar",
  "Karambe",
  "Karandagolla",
  "Karandana",
  "Karandeniya",
  "Karangoda",
  "Karaputugala",
  "Karativponparappi",
  "Karativu",
  "Karatota",
  "Karaveddi",
  "Karawitagara",
  "Kariyamaditta",
  "Karuppaddamurippu",
  "Karuwalagaswewa",
  "Kashyapapura",
  "Katana",
  "Kataragama",
  "Kathiraveli",
  "Katiyawa",
  "Kattankudi",
  "Katudeniya",
  "Katugastota",
  "Katukitula",
  "Katunayake Air Force Camp",
  "Katunayake",
  "Katuneriya",
  "Katupota",
  "Katuwana",
  "Katuwellegama",
  "Kavudupelella",
  "Kavutharimunai",
  "Kawantissapura",
  "Kawudulla",
  "Kawuduluwewa Stagell",
  "Kayts",
  "Kebillawela",
  "Kebithigollewa",
  "Keerthi Bandarapura",
  "Kegalle",
  "Kegalle",
  "Kehelpannala",
  "Kehelwatta",
  "Kekanadurra",
  "Kekirawa",
  "Kekirihena",
  "Kekunagolla",
  "Kelanigama",
  "Kelaniya",
  "Kelinkanda",
  "Kella Junction",
  "Kendagolla",
  "Kendewa",
  "Kengalla",
  "Keppetipola",
  "Keppitiwalana",
  "Keselpotha",
  "Ketaboola",
  "Ketakumbura",
  "Ketawala Leula",
  "Ketawatta",
  "Kibissa",
  "Kilinochchi",
  "Kilinochchi",
  "Kimbulapitiya",
  "Kimbulwanaoya",
  "Kiralogama",
  "Kirama",
  "Kiran",
  "Kirankulam",
  "Kiribathgoda",
  "Kiribathkumbura",
  "Kiriella",
  "Kirigalwewa",
  "Kiriibbanwewa",
  "Kirimetiyawa",
  "Kirimundalama",
  "Kirinda",
  "Kirindawa",
  "Kirindigalla",
  "Kirindiwela",
  "Kiriwanagama",
  "Kiriwattuduwa",
  "Kiriweldola",
  "Kiriwelkele",
  "Kitalawalana",
  "Kithalawa",
  "Kitulgala",
  "Kitulgoda",
  "Kitulhitiyawa",
  "Kitulwala",
  "Kiwula",
  "Kobeigane",
  "Kobonila",
  "Kochchikade",
  "Koddaikallar",
  "Kodikamam",
  "Kohilagedara",
  "Koholana",
  "Kohuwala",
  "Kokkaddichcholai",
  "Kokkilai",
  "Kokkuthuoduvai",
  "Koknahara",
  "Kokuvil",
  "Kolabissa",
  "Kolamanthalawa",
  "Kolambageara",
  "Kolawenigama",
  "Kolombugama",
  "Kolongoda",
  "Kolonna",
  "Kolonnawa",
  "Komari",
  "Kondavil",
  "Kondeniya",
  "Kongahawela",
  "Konwewa",
  "Kopay",
  "Kosdeniya",
  "Kosgama",
  "Kosgoda",
  "Kosgolla",
  "Koslanda",
  "Koswatta",
  "Kotadeniyawa",
  "Kotagala",
  "Kotagama",
  "Kotahena",
  "Kotamuduna",
  "Kotapola",
  "Kotawehera Mankada",
  "Kotawehera",
  "Kotikawatta",
  "Kotiyagala",
  "Kotiyakumbura",
  "Kotmale",
  "Kottantivu",
  "Kottapitiya",
  "Kottawa",
  "Kottawagama",
  "Kottegoda",
  "Kottellena",
  "Kottukachchiya",
  "Kotugoda",
  "Kuda Uduwa",
  "Kudagalgamuwa",
  "Kudakatnoruwa",
  "Kudatanai",
  "Kudawa",
  "Kudawewa",
  "Kuleegoda",
  "Kuliyapitiya",
  "Kulugammana",
  "Kumaragama",
  "Kumarakattuwa",
  "Kumbalgamuwa",
  "Kumbaloluwa",
  "Kumbukgeta",
  "Kumbukkana",
  "Kumbukkandura",
  "Kumbukwela",
  "Kumbukwewa",
  "Kumburegama",
  "Kundasale",
  "Kuratihena",
  "Kurinjanpitiya",
  "Kuruketiyawa",
  "Kurukkalmadam",
  "Kurundankulama",
  "Kurunegal",
  "Kurunegala",
  "Kurupanawela",
  "Kuruwita",
  "Kuruwitenna",
  "Kuttiyagolla",
  "Labbala",
  "Labukele",
  "Labunoruwa",
  "Laggala Pallegama",
  "Lahugala",
  "Lakshauyana",
  "Landewela",
  "Lankagama",
  "Laxapana",
  "Leemagahakotuwa",
  "Leliambe",
  "Lellopitiya",
  "Lenadora",
  "Lewangama",
  "Lihiriyagama",
  "Lindula",
  "Liyangahawela",
  "Loluwagoda",
  "Lonahettiya",
  "Lunama",
  "Lunugala",
  "Lunugama",
  "Lunugamwehera",
  "Lunuketiya Maditta",
  "Lunuwatta",
  "Lunuwila",
  "Mabodale",
  "Madahapola",
  "Madakumburumulla",
  "Madalagama",
  "Madampe",
  "Madapatha",
  "Madatugama",
  "Madawala Bazaar",
  "Madawala Ulpotha",
  "Madawalalanda",
  "Madelgamuwa",
  "Madhu Church",
  "Madhu Road",
  "Madipola",
  "Madugalla",
  "Madulkele",
  "Madulla",
  "Madulsima",
  "Maduragoda",
  "Madurankuliya",
  "Maduruoya",
  "Maeliya",
  "Magama",
  "Magedara",
  "Maggona",
  "Magulagama",
  "Maha Ambagaswewa",
  "Maha Elagamuwa",
  "Mahabage",
  "Mahabulankulama",
  "Mahadoraliyadda",
  "Mahagalkadawala",
  "Mahagalwewa",
  "Mahagama Colony",
  "Mahagama",
  "Mahagirilla",
  "Mahailluppallama",
  "Mahakachchakodiya",
  "Mahakalupahana",
  "Mahakanadarawa",
  "Mahakumbukkadawala",
  "Mahamedagama",
  "Mahamukalanyaya",
  "Mahanagapura",
  "Mahananneriya",
  "Mahaoya",
  "Mahapallegama",
  "Mahapothana",
  "Maharachchimulla",
  "Maharagama",
  "Maharangalla",
  "Mahasenpura",
  "Mahatalakolawewa",
  "Mahauswewa",
  "Mahawalatenna",
  "Mahawela Sinhapura",
  "Mahawela",
  "Mahawewa",
  "Mahawilachchiya",
  "Mahiyanganaya",
  "Maho",
  "Mailagaswewa",
  "Mailapitiya",
  "Makandura Sabara",
  "Makandura",
  "Makehelwala",
  "Makewita",
  "Makkanigama",
  "Makola",
  "Makuldeniya",
  "Makulella",
  "Makulewa",
  "Makulpotha",
  "Makulwewa",
  "Malabe",
  "Malagane",
  "Malalpola",
  "Maldeniya",
  "Malgalla Talangalla",
  "Malgoda",
  "Maliboda",
  "Maliduwa",
  "Maliyadda",
  "Mallakam",
  "Malmaduwa",
  "Malwala Junction",
  "Malwana",
  "Malwanagama",
  "Malwatta",
  "Mamadala",
  "Mamaduwa",
  "Mampitiya",
  "Mampuri",
  "Mananwatta",
  "Mandapola",
  "Mandaram Nuwara",
  "Mandawala",
  "Mandur",
  "Maneruwa",
  "Mangalaeliya",
  "Mangalagama",
  "Manipay",
  "Mankemi",
  "Mankulam",
  "Mannar",
  "Mannar",
  "Mapakadawewa",
  "Mapakanda",
  "Mapalagama Central",
  "Mapalagama",
  "Maradankadawala",
  "Maradankalla",
  "Maraiyadithakulam",
  "Maraka",
  "Maramba",
  "Marandagahamula",
  "Marapana",
  "Marassana",
  "Marathamune",
  "Marawa",
  "Marawila",
  "Mariarawa",
  "Marichchi Kaddi",
  "Marymount Colony",
  "Maskeliya",
  "Maspanna",
  "Maspotha",
  "Maswela",
  "Matale",
  "Matale",
  "Matara",
  "Matara",
  "Mathagal",
  "Mattaka",
  "Matugama",
  "Maturata",
  "Matuwagalagama",
  "Maussagolla",
  "Mawanagama",
  "Mawanella",
  "Mawathagama",
  "Mawatura",
  "Meda-Keembiya",
  "Medagalatur",
  "Medagana",
  "Medamahanuwara",
  "Medamulana",
  "Medawachchiya",
  "Medawala Harispattuwa",
  "Medawela Udukinda",
  "Medawelagama",
  "Meddekanda",
  "Medirigiriya",
  "Mediripitiya",
  "Medivawa",
  "Meegahakiula",
  "Meegahatenna",
  "Meegalawa",
  "Meegama",
  "Meegaswewa",
  "Meegoda",
  "Meesalai",
  "Meetalawa",
  "Meetanwala",
  "Meetiyagoda",
  "Meewellawa",
  "Megoda Kalugamuwa",
  "Megodawewa",
  "Melipitiya",
  "Mellawagedara",
  "Melsiripura",
  "Menikdiwela",
  "Menikhinna",
  "Metigahatenna",
  "Metihakka",
  "Metikumbura",
  "Metiyagane",
  "Middeniya",
  "Miella",
  "Migahajandur",
  "Migastenna Sabara",
  "Mihintale",
  "Millaniya",
  "Millawana",
  "Millewa",
  "Mimure",
  "Minhettiya",
  "Minigamuwa",
  "Minipe",
  "Minipura Dumbara",
  "Minneriya",
  "Minuwangete",
  "Minuwangoda",
  "Mipanawa",
  "Mipilimana",
  "Mirahawatta",
  "Miravodai",
  "Mirigama",
  "Mirihanagama",
  "Mirissa",
  "Miriswatta",
  "Miriyabedda",
  "Mirusuvil",
  "Mithirigala",
  "Mitipola",
  "Miwanapalana",
  "Miyanakandura",
  "Miyanawita",
  "Modarawana",
  "Molagoda",
  "Molkawa",
  "Monaragal",
  "Monaragala",
  "Monnekulama",
  "Moragahapallama",
  "Moragala Kirillapone",
  "Moragane",
  "Moragollagama",
  "Morahela",
  "Morahenagama",
  "Morakewa",
  "Morapitiya",
  "Morathiha",
  "Moratuwa",
  "Morawaka",
  "Moretuwegama",
  "Morontota",
  "Morontuduwa",
  "Mount Lavinia",
  "Mudalakkuliya",
  "Muddaragama",
  "Mudungoda",
  "Mugunuwatawana",
  "Mukkutoduwawa",
  "Mulatiyana Junction",
  "Mulendiyawala",
  "Mulgama",
  "Mulkirigala",
  "Mulkiriyawa",
  "Mullativu",
  "Mullegama",
  "Mulleriyawa",
  "Mullivaikkal",
  "Mulliyan",
  "Mulliyawalai",
  "Munamaldeniya",
  "Mundel",
  "Munwatta",
  "Murakottanchanai",
  "Murasumoddai",
  "Muriyakadawala",
  "Murunkan",
  "Murutalawa",
  "Muruthagahamulla",
  "Muruthenge",
  "Muthauyan Kaddakulam",
  "Muttibendiwila",
  "Mutugala",
  "Mutwal",
  "Muwandeniya",
  "Nabadewa",
  "Nachchaduwa",
  "Naddan Kandal",
  "Nadugala",
  "Nagar Kovil",
  "Nagoda",
  "Nagollagama",
  "Nagollagoda",
  "Naimana",
  "Nainamadama",
  "Nainathivu",
  "Nakiyadeniya",
  "Nakkala",
  "Nakkawatta",
  "Nakulugamuwa",
  "Nalanda",
  "Nalladarankattuwa",
  "Namaloya",
  "Namalpura",
  "Namunukula",
  "Nanattan",
  "Nannapurawa",
  "Nanuoya",
  "Napawela",
  "Narahenpita",
  "Narammala",
  "Narangala",
  "Narangoda",
  "Naranpanawa",
  "Naranwala",
  "Narawelpita",
  "Nattandiya",
  "Nattarampotha",
  "Naula",
  "Navagirinagar",
  "Navatkadu",
  "Navithanveli",
  "Nawadagala",
  "Nawagattegama",
  "Nawalakanda",
  "Nawalapitiya",
  "Nawamedagama",
  "Nawana",
  "Nawasenapura",
  "Nawatalwatta",
  "Nawathispane",
  "Nawattuduwa",
  "NawinnaPinnakanda",
  "Nayapana Janapadaya",
  "Neboda",
  "Nedungamuwa",
  "Nedunkemy",
  "Neervely",
  "Negampaha",
  "Negombo",
  "Nelliya",
  "Nelliyadda",
  "Nelumgama",
  "Nelumwewa",
  "Nelundeniya",
  "Neluwa",
  "Neriyakulam",
  "Netolpitiya",
  "Nihiluwa",
  "Nikadalupotha",
  "Nikahetikanda",
  "Nikapotha",
  "Nikaweratiya",
  "Nildandahinna",
  "Nilgala",
  "Nillambe",
  "Nindana",
  "Nintavur",
  "Niralagama",
  "Nissanka Uyana",
  "Nittambuwa",
  "Nivitigala",
  "Niwandama",
  "Niyadurupola",
  "Nochchiyagama",
  "Noori",
  "Norachcholai",
  "Norwood",
  "Nugagolla",
  "Nugaliyadda",
  "Nugatalawa",
  "Nugawela",
  "Nugegoda",
  "Nuwara Ela",
  "Nuwara Eliya",
  "Nuwaragala",
  "Obbegoda",
  "Oddamavadi",
  "Odduchudan",
  "Ohiya",
  "Okkampitiya",
  "Oluvil",
  "Omalpe",
  "Omanthai",
  "Onegama",
  "Opalgala",
  "Opanayaka",
  "Opatha",
  "OrubendiSiyambalawa",
  "Ovilikanda",
  "P.P.Potkemy",
  "Padagoda",
  "Padalangala",
  "Padavi Maithripura",
  "Padavi Parakramapura",
  "Padavi Sripura",
  "Padavi Sritissapura",
  "Padaviya",
  "Padawkema",
  "Padeniya",
  "Padikaramaduwa",
  "Padiwela",
  "Padiyapelella",
  "Padiyatalawa",
  "Padukka",
  "Pahala Andarawewa",
  "Pahala Halmillewa",
  "Pahala Maragahawe",
  "Pahala Millawa",
  "Pahalagama",
  "Pahalagiribawa",
  "Pahalahewessa",
  "Pahalalanda",
  "Pahalarathkinda",
  "Pahamune",
  "Paiyagala",
  "Palagala",
  "Palamoddai",
  "Palampiddy",
  "Palamunai",
  "Palapathwela",
  "Palatuwa",
  "Palaviya",
  "Pallai",
  "Pallama",
  "Pallavarayankaddu",
  "Pallebedda",
  "Pallebowala",
  "Pallekanda",
  "Pallekiruwa",
  "Pallekotuwa",
  "Pallepola",
  "Pallewela",
  "Palliwasalturai",
  "Palugasdamana",
  "Palugaswewa",
  "Palukadawala",
  "Pambagolla",
  "Pampaimadu",
  "Pamunugama",
  "Pamunuwatta",
  "Panadaragama",
  "Panadura",
  "Panagamuwa",
  "Panaliya",
  "Panama",
  "Panamura",
  "Panangala",
  "Panapitiya",
  "Panapola",
  "Panawala",
  "Pandaterippu",
  "Pandukabayapura",
  "Pandulagama",
  "Pangura",
  "Panichankemi",
  "Panirendawa",
  "Pankudavely",
  "Panliyadda",
  "Pannala",
  "Pannalagama",
  "Pannila",
  "Pannimulla Panagoda",
  "Pannipitiya",
  "Pansiyagama",
  "Panvila",
  "Panwilatenna",
  "Paradeka",
  "Paragahakele",
  "Paragala",
  "Paragastota",
  "Paragoda",
  "Paraigama",
  "Parakaduwa",
  "Parakramasamudraya",
  "Parakumpura",
  "Parana ThanaYamgoda",
  "Parangiyawadiya",
  "Paranthan",
  "Parapamulla",
  "Parape",
  "Parasangahawewa",
  "Pasbage",
  "Pasgoda",
  "Passara",
  "Pasyala",
  "Patana",
  "Pathanewatta",
  "Pattampitiya",
  "Pattitalawa",
  "Pattiya Watta",
  "Pattiyagedara",
  "Pavaikulam",
  "Pebotuwa",
  "Pelagahatenna",
  "Pelanda",
  "Pelatiyawa",
  "Pelawatta",
  "Peliyagoda",
  "Pelmadulla",
  "Pemaduwa",
  "Penetiyana",
  "Penideniya",
  "Pepiliyawala",
  "Peradeniya",
  "Perakanatta",
  "Perawella",
  "Perimiyankulama",
  "Periyakadneluwa",
  "Periyakunchikulam",
  "Periyamadhu",
  "Periyaneelavanai",
  "Periyaporativu",
  "Periyapullumalai",
  "Periyathambanai",
  "Periyaulukkulam",
  "Pesalai",
  "Pethiyagoda",
  "Pihimbiya Ratmale",
  "Pihimbiyagolewa",
  "Pihimbuwa",
  "Pilawala",
  "Pilessa",
  "Pilimatalawa",
  "Piliyandala",
  "Pillaiyaradi",
  "Pimbura",
  "Pimburattewa",
  "Pinnawala",
  "Pita Kotte",
  "Pitabeddara",
  "Pitagaldeniya",
  "Pitakumbura",
  "Pitamaruwa",
  "Pitapola",
  "Pitawala",
  "Pitigala",
  "Pitipana Homagama",
  "Poddala",
  "Poholiyadda",
  "Point Pedro",
  "Pokunuwita",
  "Polgahawela",
  "Polgampola",
  "Polgasowita",
  "Polgolla",
  "Polonnaruwa",
  "Polonnaruwa",
  "Polpithimukulana",
  "Polpitigama",
  "Polwaga Janapadaya",
  "Porawagama",
  "Poruwedanda",
  "Pothdeniya",
  "Pothuhera",
  "Pothukoladeniya",
  "Pothupitiya",
  "Pothuwatawana",
  "Pottuvil",
  "Pubbiliya",
  "Pubbogama",
  "Pugoda",
  "Puhulpola",
  "Puhulwella",
  "Pujapitiya",
  "Pulastigama",
  "Pulmoddai",
  "Punanai",
  "Pundaluoya",
  "Punewa",
  "Pungudutivu",
  "Pupuressa",
  "Purakari Nallur",
  "Pussellawa",
  "Puswelitenna",
  "Puthukudiyiruppu",
  "Puthuvedduvan",
  "Puttalam Cement Factory",
  "Puttalam",
  "Puttalam",
  "Putuhapuwa",
  "Putur",
  "Puwakpitiya",
  "RU/Ridiyagama",
  "Radawadunna",
  "Radawana",
  "Radawela",
  "Raddolugama",
  "Ragama",
  "Rajagalatenna",
  "Rajagiriya",
  "Rajakadaluwa",
  "Rajanganaya",
  "Rajawaka",
  "Rajawella",
  "Rakwana",
  "Ramanathapuram",
  "Rambewa",
  "Ramboda",
  "Rambukkana",
  "Rambukpitiya",
  "Rambukwella",
  "Rammalawarapitiya",
  "Rampathwila",
  "Ranakeliya",
  "Ranala",
  "Ranamuregama",
  "Randeniya",
  "Rangala",
  "Ranmuduwewa",
  "Ranna",
  "Ranorawa",
  "Ransegoda",
  "Rantembe",
  "Rantotuwila",
  "Ranwala",
  "Rassagala",
  "Ratgama",
  "Rathmalgahawewa",
  "Rathukohodigala",
  "Ratkarawwa",
  "Ratmalana",
  "Ratmale",
  "Ratmalwala",
  "Ratna Hangamuwa",
  "Ratnapura",
  "Ratnapura",
  "Rattota",
  "Remunagoda",
  "Ridibendiella",
  "Ridigama",
  "Ridimaliyadda",
  "Rikillagaskada",
  "Rilpola",
  "Rotumba",
  "Rozella",
  "Ruggahawila",
  "Rukmale",
  "Rupaha",
  "Ruwalwela",
  "Ruwaneliya",
  "Ruwanwella",
  "Sainthamaruthu",
  "Saliya Asokapura",
  "Saliyapura",
  "Saliyawewa Junction",
  "Samanalawewa",
  "Samanthurai",
  "Sandalankawa",
  "Sandilipay",
  "Sangarajapura",
  "Santhipura",
  "Sasthrikulankulam",
  "Seaforth Colony",
  "Seeduwa",
  "Seeppukulama",
  "Selagama",
  "Sella Kataragama",
  "Senapura",
  "Senarathwela",
  "Serankada",
  "Serukele",
  "Sevanapitiya",
  "Sewanagala",
  "Siddamulla",
  "Sigiriya",
  "Silmiyapura",
  "Sinhagama",
  "Sirambiadiya",
  "Sirimalgoda",
  "Siripura",
  "Sirisetagama",
  "Sithankemy",
  "Sivalakulama",
  "Sivapuram",
  "Siyambalagashene",
  "Siyambalagoda",
  "Siyambalagune",
  "Siyambalanduwa",
  "Siyambalangamuwa",
  "Siyambalape",
  "Siyambalawewa",
  "Siyambalewa",
  "Skanthapuram",
  "Solepura",
  "Solewewa",
  "Sooriyawewa Town",
  "Sorabora Colony",
  "Soragune",
  "Soranatota",
  "Spring Valley",
  "Sravasthipura",
  "Sri Jayewardenepura",
  "Sri Palabaddala",
  "Sudagala",
  "Sultanagoda",
  "Sunandapura",
  "Sungavila",
  "Suriara",
  "Tabbowa",
  "Talagampola",
  "Talagoda Junction",
  "Talahena",
  "Talaimannar",
  "Talakiriyagama",
  "Talakolahinna",
  "Talatuoya",
  "Talawa",
  "Talawakele",
  "Talawattegedara",
  "Talawatugoda",
  "Talawila Church",
  "Taldena",
  "Talgaspe",
  "Talgaspitiya",
  "Talgaswela",
  "Talpe",
  "Talpotha",
  "Tamankaduwa",
  "Tambala",
  "Tambutta",
  "Tambuttegama",
  "Tammennawa",
  "Tanabaddegama",
  "Tanamalwila",
  "Tangalla",
  "Tanjantenna",
  "Tantirimale",
  "Tawalama",
  "Tawalantenna",
  "Tebuwana",
  "Teldeniya",
  "Telhiriyawa",
  "Teligama",
  "Telijjawila",
  "Tellippallai",
  "Tempitiya",
  "Temple",
  "Tennamarawadiya",
  "Tennekumbura",
  "Tennepanguwa",
  "Teppanawa",
  "Teripeha",
  "Thalahitimulla",
  "Thalakolawewa",
  "Thalayadi",
  "Thalwita",
  "Thambagalla",
  "Thambiluvil",
  "Thannamunai",
  "Tharana Udawela",
  "Tharapuram",
  "Tharmapuram",
  "Thettativu",
  "Thihagoda",
  "Thikkodai",
  "Thimbirigaskatuwa",
  "Thimbiriyawa",
  "Thiruketheeswaram Temple",
  "Thirupalugamam",
  "Tholangamuwa",
  "Thondamanaru",
  "Thotawella",
  "Thunukkai",
  "Thuraineelavanai",
  "Timbirigaspitiya",
  "Tiranagama",
  "Tirappane",
  "Tirukovil",
  "Tisogama",
  "Tissamaharama",
  "Tittagonewa",
  "Tittapattara",
  "Toduwawa",
  "Torayaya",
  "Trincomal",
  "Tulhiriya",
  "Tummodara",
  "Tunkama",
  "Tuntota",
  "Tuttiripitigama",
  "Uda Gomadiya",
  "Uda Peradeniya",
  "Udagaldeniya",
  "Udaha Hawupe",
  "Udahentenna",
  "Udahingulwala",
  "Udakarawita",
  "Udalamatta",
  "Udamadura",
  "Udamattala",
  "Udaniriella",
  "Udapotha",
  "Udappuwa",
  "Udapussallawa",
  "Udasgiriya",
  "Udatalawinna",
  "Udatenna",
  "Udathuthiripitiya",
  "Udawalawe",
  "Udawatta",
  "Udayarkaddu",
  "Udispattuwa",
  "Udubaddawa",
  "Ududumbara",
  "Udugama",
  "Udugampola",
  "Uduhawara",
  "Udumulla",
  "Udunuwara Colony",
  "Uduwa",
  "Uduwahinna",
  "Uduwara",
  "Uduwela",
  "Uggalboda",
  "Uhana",
  "Uhumiya",
  "Ukuwela",
  "Ulapane",
  "Ullinduwawa",
  "Ulpotha Pallekele",
  "Ulpothagama",
  "Uluvitike",
  "Unagalavehera",
  "Unawatuna",
  "Undugoda",
  "Unenwitiya",
  "Unnichchai",
  "Unuwinna",
  "Upuldeniya",
  "Uragaha",
  "Uragasmanhandiya",
  "Uraniya",
  "Urapola",
  "Uridyawa",
  "Urubokka",
  "Urugamuwa",
  "Urumpirai",
  "Urumutta",
  "Uruthirapuram",
  "Usgala Siyabmalangamuwa",
  "Ussapitiya",
  "Uswetakeiyawa",
  "Uswewa",
  "Uttimaduwa",
  "Utumgama",
  "Uva Deegalla",
  "Uva Gangodagama",
  "Uva Karandagolla",
  "Uva Kudaoya",
  "Uva Mawelagama",
  "Uva Pelwatta",
  "Uva Tenna",
  "Uva Tissapura",
  "Uva Uduwara",
  "Uvaparanagama",
  "Uyilankulam",
  "Vaddakachchi",
  "Vaddakandal",
  "Vaddukoddai",
  "Vakaneri",
  "Vakarai",
  "Valaichenai",
  "Valvettithurai",
  "Vanathawilluwa",
  "Vankalai",
  "Vannarponnai",
  "Vannerikkulam",
  "Vantharumoolai",
  "Varany",
  "Varikkuttiyoor",
  "Vasavilan",
  "Vavunakkulam",
  "Vavuniya",
  "Vavuniya",
  "Veddagala",
  "Velamboda",
  "Velanai",
  "Vellan Kulam",
  "Vellavely",
  "Veravil",
  "Veyangalla",
  "Veyangoda",
  "Vidataltivu",
  "Viharahena",
  "Viharapalugama",
  "Vijeriya",
  "Vijithapura",
  "Vinayagapuram",
  "Visvamadukulam",
  "Vitharandeniya",
  "Wadakada",
  "Wadduwa",
  "Wadinagala",
  "Wadumunnegedara",
  "Waga",
  "Wahacotte",
  "Wahakula",
  "Wahalkada",
  "Wahamalgollewa",
  "Waharaka",
  "Waikkal",
  "Wakwella",
  "Walagambahuwa",
  "Walagedara",
  "Walahanduwa",
  "Walahaviddawewa",
  "Walakanda",
  "Walakumburumulla",
  "Walallawita",
  "Walapane",
  "Walasgala",
  "Walasmulla",
  "Walawela",
  "Waleboda",
  "Walgammulla",
  "Walpita",
  "Walpola",
  "Wanagamuwa",
  "Wanaluwewa",
  "Wanchawela",
  "Wanduramba",
  "Wannigama",
  "Wannikudawewa",
  "Wannilhalagama",
  "Wannirasnayakapura",
  "Warakapola",
  "Waralla",
  "Warawewa",
  "Wariyapola",
  "Warukandeniya",
  "Warunagama",
  "Waskaduwa",
  "Watagoda Harispattuwa",
  "Watagoda",
  "Watapotha",
  "Watareka",
  "Watawala",
  "Wathurugama",
  "Watinapaha",
  "Wattala",
  "Wattappola",
  "Wattegama",
  "Watugahamulla",
  "Watugedara",
  "Watura",
  "Waturawa",
  "Watuwatta",
  "Weboda",
  "Wedikumbura",
  "Weeoya",
  "Weeraketiya",
  "Weerapokuna",
  "Weerawila NewTown",
  "Weerawila",
  "Wegalla",
  "Wegowwa",
  "Weherayaya Handapanagala",
  "Wehigala",
  "Weihena",
  "Wekandawela",
  "Welangahawatte",
  "Welawa Juncton",
  "Weligalla",
  "Weligama",
  "Weligampola",
  "Weligatta",
  "Weligepola",
  "Welihelatenna",
  "Welikanda",
  "Welimada",
  "Welimuwapotana",
  "Welioya Project",
  "Welipathayaya",
  "Welipenna",
  "Welipennagahamulla",
  "Weliveriya",
  "Wellagala",
  "Wellarawa",
  "Wellawa",
  "Wellawaya",
  "Welmilla Junction",
  "Welpalla",
  "Wendaruwa",
  "Wennappuwa",
  "Wennoruwa",
  "Weragala",
  "Weragantota",
  "Werapitya",
  "Werellagama",
  "Werunketagoda",
  "Wettawa",
  "Weuda",
  "Wewagama",
  "Wewalawewa",
  "Wewatta",
  "Weweldeniya",
  "Wewelwatta",
  "Widulipura",
  "Wijayabapura",
  "Wijebahukanda",
  "Wijerama",
  "Wijeyakatupotha",
  "Wikiliya",
  "Wilanagama",
  "Wilaoya",
  "Wilgamuwa",
  "Wilpita",
  "Wilpotha",
  "Wineethagama",
  "Yagirala",
  "Yahalatenna",
  "Yakkala",
  "Yakkalamulla",
  "Yakwila",
  "Yalagamuwa",
  "Yalwela",
  "Yatadolawatta",
  "Yatagama",
  "Yatalamatta",
  "Yatapana",
  "Yatawara Junction",
  "Yatawatta",
  "Yatigala",
  "Yatigaloluwa",
  "Yatihalagala",
  "Yatiyana",
  "Yatiyantota",
  "Yattogoda",
  "Yodaela",
  "Yogapuram",
  "Yogiyana",
  "Yudaganawa",
  "lbbagamuwa",
  "lhala Halmillewa",
  "lhala Kadigamuwa",
  "lhala Kobbekaduwa",
  "lhalagama",
  "lhalahewessa",
  "llavalai",
  "lllagolla",
  "lllukkumbura",
  "llluppaikadavai",
  "llukhena",
  "lmaduwa",
  "lmbulpe",
  "lmkkamam",
  "lnduruwa",
  "lngiriya",
  "lpologama",
  "lyakachchi",
]
