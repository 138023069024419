import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: true,
    fallbackLng: "si",
    resources: {
      si: {
        translation: {
          title: "වට්ටම් හා දීමනා",
          title2: "පිටුවට සාදරෙයන්",
          title3: "පිළිගනිමු",
          title4: "කරුණාකර පහතින් ඔබේ ප්‍රදේශය තෝරන්න",
          dropdown: "ඔබගේ ප්‍රදේශය තෝරන්න...",
          button1: "පිටුවට පිවිසෙන්න",
          mainheading1: "නවතම ප්‍රවර්ධන සොයා ගන්න",
          mainheading2: "ඔබේ ප්‍රදේශයේ වට්ටම් සොයා ගැනීමට පහත පතන භාවිත කළ හැක",
          numofpromo: " වට්ටම් ඇත.",
          promotype: "අලුත්ම වට්ටම්",
          product: "වට්ටම් - වටවල තේ",
          vduration: "වලංගු කාලය",
          //promotypes
          typepromo1: "නවතම වට්ටම",
          typepromo2: "ඉහළම වට්ටම",
          typepromo3: "නිෂ්පාදන වර්ගය",

          // promotion-items
          promotypename: "25% - වටිටම් - වටවල තේ 500g",
          promotimeduration: "වලංගු කාලය : 27/02/2023",
        },
      },
      ta: {
        translation: {
          title: "கழிவுகள் மற்றும் கொடுப்பனவுகள்",
          title2: "பக்கத்திற்கு அன்புடன்",
          title3: "வரவேற்கின்றோம",
          title4: "தயவுசெய்து கீழே உங்கள் பிரதேசத்தின தெரிவுசெய்திடுங்கள்",
          dropdown: "பிரதேசத்தினை தெரிவுசெய்திடுங்கள்",
          button1: "பக்கத்திற்கு பிரவேசியுங்கள",
          mainheading1: "புதிய கொடுப்பனவுகளை அறிந்திடுங்கள",
          mainheading2:
            "உங்கள் பிரதேசத்திற்கான கழிவுகள அறிந்திட கீழே தெரிவுசெய்திடுங்கள",
          numofpromo: " கழிவுகள் உள்ளன",
          promotype: "புதிய கழிவுகள",
          product: "கழிவு - வட்டவளை தேயிலை",
          vduration: "செல்லுப்படியாகும் காலம",
          //promotypes
          typepromo1: "சமீபத்திய தள்ளுபடி",
          typepromo2: "அதிகபட்ச தள்ளுபடி",
          typepromo3: "உற்பத்தி பொருள் வகை",
          // promotion-items
          promotypename: "25% - தள்ளுபடி - சுற்று தேநீர் 500g",
          promotimeduration: "செல்லுபடியாகும் காலம் : 27/02/2023",
        },
      },
    },
  })
