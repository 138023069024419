import React, { useState, useRef } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { Transition, Dialog } from "@headlessui/react"
import { cities } from "../data/cities"
import { useEffect } from "react"
import { Fragment } from "react"
import {
  XCircleIcon,
  ArrowRightIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline"
import { Menu } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"
import "../i18n"
import { initReactI18next } from "react-i18next"
import { useTranslation, Trans } from "react-i18next"

const Promotion = ({ data }) => {
  const [visible, setVisible] = useState(12)
  const [isOpen, setOpen] = useState(false)
  const [activeCity, setActiveCity] = useState(false)
  const [activeCity1, setActiveCity1] = useState(false)
  const [active, setActive] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")

  const filteredCities = cities.filter(city =>
    city.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const { t, i18n } = useTranslation()

  const language = {
    si: { nativeName: "සිංහල" },
    ta: { nativeName: "தமிழ்" },
  }

  console.log(active)

  const dlist = [
    { id: 1, title: <Trans i18nKey="typepromo1" /> },

    { id: 2, title: <Trans i18nKey="typepromo2" /> },

    { id: 3, title: <Trans i18nKey="typepromo3" /> },
  ]

  function handleSelect(data) {
    setSelectedOptions(data)
  }

  const [selectedOptions, setSelectedOptions] = useState()

  const images = {
    insideHeader: getImage(data.insideHeader),
    pimage: getImage(data.promotion),
    gimage: getImage(data.globe),
    simage: getImage(data.search),
    fimage: getImage(data.filter),
    cimage: getImage(data.close),

    header: convertToBgImage(getImage(data.header)),
  }

  const items = [
    {
      id: 1,
      title: <Trans i18nKey="promotypename" />,
      location: "Keels",
      description: <Trans i18nKey="promotimeduration" />,
    },
    {
      id: 2,
      title: <Trans i18nKey="promotypename" />,
      location: "Keels",
      description: <Trans i18nKey="promotimeduration" />,
    },
    {
      id: 3,
      title: <Trans i18nKey="promotypename" />,
      location: "Keels",
      description: <Trans i18nKey="promotimeduration" />,
    },
    {
      id: 4,
      title: <Trans i18nKey="promotypename" />,
      location: "Keels",
      description: <Trans i18nKey="promotimeduration" />,
    },
    {
      id: 5,
      title: <Trans i18nKey="promotypename" />,
      location: "Keels",
      description: <Trans i18nKey="promotimeduration" />,
    },
    {
      id: 6,
      title: <Trans i18nKey="promotypename" />,
      location: "Keels",
      description: <Trans i18nKey="promotimeduration" />,
    },
    {
      id: 7,
      title: <Trans i18nKey="promotypename" />,
      location: "Keels",
      description: <Trans i18nKey="promotimeduration" />,
    },
    {
      id: 8,
      title: <Trans i18nKey="promotypename" />,
      location: "Keels",
      description: <Trans i18nKey="promotimeduration" />,
    },
    {
      id: 9,
      title: <Trans i18nKey="promotypename" />,
      location: "Keels",
      description: <Trans i18nKey="promotimeduration" />,
    },
    {
      id: 10,
      title: <Trans i18nKey="promotypename" />,
      location: "Keels",
      description: <Trans i18nKey="promotimeduration" />,
    },
    {
      id: 11,
      title: <Trans i18nKey="promotypename" />,
      location: "Keels",
      description: <Trans i18nKey="promotimeduration" />,
    },
    {
      id: 12,
      title: <Trans i18nKey="promotypename" />,
      location: "Keels",
      description: <Trans i18nKey="promotimeduration" />,
    },
    {
      id: 11,
      title: <Trans i18nKey="promotypename" />,
      location: "Keels",
      description: <Trans i18nKey="promotimeduration" />,
    },
    {
      id: 12,
      title: <Trans i18nKey="promotypename" />,
      location: "Keels",
      description: <Trans i18nKey="promotimeduration" />,
    },

    {
      id: 11,
      title: <Trans i18nKey="promotypename" />,
      location: "Keels",
      description: <Trans i18nKey="promotimeduration" />,
    },
    {
      id: 12,
      title: <Trans i18nKey="promotypename" />,
      location: "Keels",
      description: <Trans i18nKey="promotimeduration" />,
    },
  ]

  const [activeLanguage, setActiveLanguage] = useState("sinhala")

  const handleLanguageChange = lang => {
    setActiveLanguage(lang)
  }

  const dialogRef = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0)

  // const handleClose = () => {
  //   dialogRef.current.close()
  // }

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          ref={dialogRef}
          as="div"
          className="relative z-20"
          open={isOpen}
          onClose={() => {
            setOpen(false)
          }}
          style={{
            width: "350",
            maxwidth: "500px",
            height: "400",
            maxheight: "550px",
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-6 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="transform absolute rounded-2xl bg-yellowshade md:p-3 p-3  align-middle shadow-xl transition-all ">
                  <Dialog.Title as="h3" className=" font-medium leading-6   ">
                    <div>
                      <XCircleIcon
                        onClick={() => setOpen(false)}
                        className="absolute cursor-pointer text-black/70 top-2 right-2 md:w-8 w-5"
                      />
                    </div>
                    <section>
                      <div className="container py-1">
                        <div className="flex flex-row items-center justify-center gap-3">
                          {Object.keys(language).map((lng, index) => (
                            <div key={index}>
                              <button
                                onClick={() => {
                                  setActiveIndex(index)
                                  i18n.changeLanguage(lng)
                                }}
                                disabled={i18n.resolvedLanguage === lng}
                                className={
                                  activeIndex === index
                                    ? "text-white bg-black hover:bg-black rounded-full"
                                    : "text-gray bg-lightgray hover:bg-darkgray rounded-full"
                                }
                              >
                                <div className="flex font-nirmala rounded-full duration-300 px-1">
                                  <div>
                                    <GlobeAltIcon className="flex gap-2 md:w-6 w-5 pl-2  py-3" />
                                  </div>
                                  <div className="md:text-sm text-sm md:pr-2 pr-1 md:px-1 px-0.5 md:py-2 py-2">
                                    {language[lng].nativeName}
                                  </div>
                                </div>
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </section>
                  </Dialog.Title>
                  <div className="container  md:mt-2 mt-3">
                    <div class="md:text-3xl text-xl md:py-1 md:px-4 px-2 text-lightblack flex justify-center font-nirmala">
                      <p class="text-center font-bold leading-10">
                        <Trans i18nKey="title" />
                        <br />
                        <Trans i18nKey="title2" />
                        <br />
                        <Trans i18nKey="title3" />
                      </p>
                    </div>
                    {/* <div className="flex pt-2 md:justify-center justify-center">
                      <div class="   text-base bg-yellow/50 rounded-full text-brown font-thin font-nirmala">
                        අපි ඔබේ ප්‍රදේශය බව හදුනා ගත්තෙමු
                      </div>
                    </div> */}
                    <div class="md:text-lg text-base md:py-4 py-3  text-lightblack font-extrabold flex justify-center mt-1 font-nirmala  ">
                      <Trans i18nKey="title4" />
                    </div>
                    <div className="relative mt-5">
                      <div
                        className="justify-center md:justify-start"
                        onClick={() => setActiveCity(!activeCity)}
                      >
                        <div className="bg-white rounded-lg p-4 cursor-pointer">
                          <div className="flex gap-1   font-nirmala">
                            <div className="mt-2 ml-3 justify-start">
                              {
                                <GatsbyImage
                                  image={getImage(images.simage)}
                                  className="md:w-5 w-4"
                                />
                              }
                            </div>
                            <div className="px-4 mt-2 md:text-base text-xs text-left ">
                              <input
                                type="text"
                                className="rounded-lg focus:outline-none "
                                placeholder={cities[active]}
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                                onClick={() => setActiveCity(true)}
                              />
                              {/* {cities[active]} */}
                            </div>
                            <div className="flex justify-end w-full">
                              <ChevronDownIcon
                                className={`md:w-5 w-4 duration-200 text-lightblack  ${
                                  activeCity1 ? "rotate-180" : ""
                                }`}
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {activeCity && (
                        <div className="absolute md:top-[4.2em] top-[4.2em] h-[30vh] overflow-y-scroll z-40 w-auto mx-auto inset-x-0  rounded-lg shadow-lg bg-white p-2">
                          {filteredCities.map((item, index) => {
                            return (
                              <div
                                className="hover:bg-yellow/10 duration-200 cursor-pointer py-2 text-sm rounded-[5px] text-left"
                                key={index}
                                disabled={index === 0}
                                onClick={() => {
                                  setActive(index)
                                  setActiveCity(false)
                                }}
                              >
                                {item}
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </div>

                  <div class="flex justify-center">
                    <div className="md:mt-2 m-4 md:py-2 py-1">
                      <button
                        type="button"
                        className=" duration-200 font-semibold text-black bg-yellow text-center rounded-full cursor-pointer md:py-4 py-2 md:px-6 px-4 group mx-auto md:ml-0 font-nirmala"
                        onClick={() => {
                          setOpen(false)
                        }}
                      >
                        <Link to="#online">
                          <div className="flex items-center">
                            <div class="md:text-base text-xs">
                              <Trans i18nKey="button1">
                                <div>පිටුවට පිවිසෙන්න</div>
                              </Trans>
                            </div>
                            <div className="md:ml-4 ml-2 group-hover:ml-6 duration-300">
                              <ArrowRightIcon className="md:w-5 w-3" />
                            </div>
                          </div>
                        </Link>
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Layout>
        <Seo title="promotion" />
        <section>
          <div>
            <div>
              <BackgroundImage
                {...images.header}
                className="bg-contain w-full h-screen"
                style={{
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  paddingTop: 0, // remove any padding on the top
                  paddingBottom: 0,
                }}
              >
                <div className="flex space-y-4  justify-center md:justify-start items-center h-full w-full relative z-20 container mx-auto md:p-10 p-8 md:flex-row   flex-col">
                  <div className="md:pl-10   md:mt- mt-12  ">
                    <div className="flex flex-row  md:justify-start justify-center gap-3">
                      {Object.keys(language).map((lng, index) => (
                        <div key={index}>
                          <button
                            onClick={() => {
                              setActiveIndex(index)
                              i18n.changeLanguage(lng)
                            }}
                            disabled={i18n.resolvedLanguage === lng}
                            className={
                              activeIndex === index
                                ? "text-white bg-black hover:bg-black rounded-full"
                                : "text-gray bg-lightgray hover:bg-darkgray rounded-full"
                            }
                          >
                            <div className="flex font-nirmala rounded-full duration-300 px-1">
                              <div>
                                <GlobeAltIcon className="flex gap-2 md:w-6 w-5 pl-2 md:py-3 py-2" />
                              </div>
                              <div className="md:text-sm text-sm md:pr-2 pr-1 md:px-1 px-0.5 md:py-2 py-1">
                                {language[lng].nativeName}
                              </div>
                            </div>
                          </button>
                        </div>
                      ))}
                    </div>
                    <div class="text-3xl md:text-4xl text-center md:text-start text-black font-semibold py-1 font-nirmala mt-4 mb-4">
                      <Trans i18nKey="mainheading1" />
                    </div>

                    <div className="py-1 text-black font-semibold md:text-lg text-base	text-center md:text-start font-nirmala mt-4">
                      <Trans i18nKey="mainheading2" />
                    </div>
                    <div className="flex w-full md:justify-start justify-center">
                      <div className="relative mt-4 py-3">
                        <div
                          className="flex w-full md:justify-center justify-start  "
                          onClick={() => setActiveCity1(!activeCity1)}
                        >
                          <div className="bg-white rounded-lg p-4 cursor-pointer">
                            <div className="flex gap-1   font-nirmala">
                              <div className=" mt-2 md:mt-1 ml-3 justify-start">
                                {
                                  <GatsbyImage
                                    image={getImage(images.simage)}
                                    className="md:w-5 w-4"
                                  />
                                }
                              </div>
                              <div className="px-4 mt-2 md:mt-1 md:text-base text-xs text-left ">
                                <input
                                  type="text"
                                  className="rounded-lg focus:outline-none "
                                  placeholder={cities[active]}
                                  value={searchTerm}
                                  onChange={e => setSearchTerm(e.target.value)}
                                  onClick={() => setActiveCity(true)}
                                />
                                {/* {cities[active]} */}
                              </div>
                              <div className="flex justify-end w-full">
                                <ChevronDownIcon
                                  className={`md:w-5 w-4 duration-200 text-lightblack  ${
                                    activeCity1 ? "rotate-180" : ""
                                  }`}
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {activeCity1 && (
                          <div className="absolute md:top-[4.7em] top-[4.9em] h-[25vh] overflow-y-scroll z-40 md:w-[324px] w-[274px]   lg:inset-x-0 md:inset-x-0 sm:inset  rounded-lg shadow-lg bg-white p-2">
                            {filteredCities.map((item, index) => {
                              return (
                                <div
                                  className="hover:bg-yellow/10 duration-200  cursor-pointer py-2 text-sm rounded-[5px] text-left"
                                  key={index}
                                  onClick={() => {
                                    setActive(index)
                                    setActiveCity(false)
                                  }}
                                >
                                  {item}
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className=" md:mt-4 mt-2 text-center md:text-start "></div>
                  </div>
                </div>
                <div className="h-full w-full  absolute top-0 z-10"></div>
              </BackgroundImage>
            </div>
          </div>
        </section>

        <section>
          <div className="container mx-auto p-10">
            <div class="flex sm:justify-between sm:flex-row flex-col items-center justify-center">
              <div class="flex justify-start font-nirmala font-semibold md:py-2 py-1 md:px-8 px-5 ml-2 lg:text-base md:text-sm text-xs">
                {items.length}
                <Trans i18nKey="numofpromo" />
              </div>

              <div class="flex justify-end ">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="flex gap-5 items-center sm:mt-0 mt-4 uppercase text-lightblack border-lightgray border  rounded-full cursor-pointer  px-4 py-2  font-nirmala">
                      <div class="md:w-5 w-3 flex items-center">
                        <GatsbyImage image={images.fimage} />
                      </div>
                      <div class="flex   justify-start  font-nirmala lg:text-base md:text-sm text-xs font-semibold text-lightblack">
                        <Trans i18nKey="promotype" />
                      </div>
                      <div class="">
                        <ChevronDownIcon
                          className="md:w-5 w-3 text-lightblack drop-shadow-md"
                          aria-hidden="true"
                        />
                      </div>
                    </Menu.Button>
                  </div>
                  <div className="overflow-x-auto max-h-20 rounded-lg">
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="flex absolute justify-between  md:right-1  font-nirmala z-10 mt-2 font-semibold text-lightblack origin-top-center divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-lightgray">
                        <Menu.Item>
                          <div className="p-5 ">
                            {dlist.map(dlist => {
                              return (
                                <option
                                  option={dlist}
                                  value={selectedOptions}
                                  onChange={handleSelect}
                                  className="py-2 md:px-6 px-3 rounded-2xl justify-center md:text-base text-[10px] relative cursor-pointer hover:bg-yellow/20 duration-100 md:text-right text-center "
                                >
                                  <div>{dlist.title}</div>
                                </option>
                              )
                            })}
                          </div>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </div>
                </Menu>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mx-auto  md:p-16 p-5 ">
            <div class="flex justify-center">
              <div class="grid grid-cols-1 md:grid-cols-2  gap-x-6 gap-y-6  ">
                {items.slice(0, visible).map((item, index) => {
                  return (
                    <div key={item.id}>
                      <div class="bg-white  rounded-xl md:p-5 p-4 shadow-xl ">
                        <div class="flex md:flex-row flex-col items-center gap-5  ">
                          <div class="md:w-1/4 w-1/3 px-4 pt-5 z-">
                            {<GatsbyImage image={images.pimage} />}
                          </div>
                          <div>
                            <div class=" font-semibold  md:justify-center tracking-widest md:text-xl   font-nirmala">
                              {item.title}
                            </div>
                            <div className="flex md:justify-start justify-center">
                              <div class="px-4 inline-block  text-base  my-3 bg-yellow/50 rounded-full text-brown font-thin font-nirmala">
                                {item.location}
                              </div>
                            </div>
                            <div class=" justify-center md:text-left text-center font-nirmala  text-base text-gray">
                              {item.description}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mx-auto p-10">
            <div class="flex justify-center mb-10">
              <div class="uppercase font-light tracking-widest">
                <div class="">
                  <button
                    type="submit"
                    class=" tracking-wide uppercase drop-shadow-lg  font-nirmala border-yellow transition ease-in-out delay-150 md:text-base sm:text-sm bg-yellow hover:-translate-y-1 hover:scale-110 text-black  font-semibold rounded-full md:px-6 px-4 md:py-2 py-1 hover:border-black duration-300 flex gap-3 items-center cursor-pointer"
                    onClick={() => {
                      setVisible(visible + 2)
                    }}
                  >
                    Load More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Promotion

export const query = graphql`
  query ProductpageImages {
    header: file(relativePath: { eq: "promotion/promotionheader.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100)
      }
    }

    promotion: file(relativePath: { eq: "promotion/image1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100)
      }
    }

    globe: file(relativePath: { eq: "promotion/globe.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100)
      }
    }

    search: file(relativePath: { eq: "promotion/search.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100)
      }
    }

    filter: file(relativePath: { eq: "promotion/filter.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100)
      }
    }

    close: file(relativePath: { eq: "promotion/close.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100)
      }
    }

    tea: file(relativePath: { eq: "pages/home/tea.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    csrBg: file(relativePath: { eq: "pages/home/csrBg.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
